import { template as template_de032dd14974442d8bbbc44b11c6c6bc } from "@ember/template-compiler";
const WelcomeHeader = template_de032dd14974442d8bbbc44b11c6c6bc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
