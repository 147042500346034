import { template as template_5e9502eb58cf41f0a9bef1c3020e5898 } from "@ember/template-compiler";
const FKLabel = template_5e9502eb58cf41f0a9bef1c3020e5898(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
